import React from "react";
import ReactDOM from "react-dom/client";
import './index.css';
import App from "./App";

const rootElement = ReactDOM.createRoot(document.getElementById("root"));

rootElement.render(
      <App />
);
